import React from "react"
import Button from "../Button"
import SwiperComp from "../SwiperComp"
import Project from "./Project"
import styles from "./Projects.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import data from "../../data//sectionTexts"

const getProjects = graphql`
  query {
    allContentfulPridatNovyProjekt {
      projects: edges {
        node {
          id
          slug
          nazevProjektu
          kratkyPopis
          hlavniFotka {
            description
            fluid(maxWidth: 500, quality: 50) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const Projects = ({ slug }) => {
  const { allContentfulPridatNovyProjekt: { projects }} = useStaticQuery(getProjects)
  const { title, desc, button } = data.projects;

  // if there is slug => make array of projects except the one that doesnt equal slug
  const newProjects = slug
    ? projects.filter(project => project.node.slug !== slug)
    : [...projects]

  // shuffle array
  function shuffleArray(array) {
    const newArray = [...array]
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray
  }

  // random array of 4 projects
  const randomProjects = shuffleArray(newProjects).slice(0,4);

  return (
    <section className={styles.projectsSection}>
      <div className={styles.projectsText}>
        <h2>
          {title.thin}<span className="bold">{title.bold}</span>
        </h2>
        <p className={styles.text}>
          {desc}
        </p>
      </div>
      <div className={styles.projects}>
        <SwiperComp hideButtons>
          {randomProjects.map(project => (
            <Project key={project.node.id} project={project} />
          ))}
        </SwiperComp>
      </div>
      <div className={styles.button}>
        <Button link="/projekty" color="blue">
          {button}
        </Button>
      </div>
    </section>
  )
}

export default Projects

Projects.propTypes = {
  slug: PropTypes.string,
}
