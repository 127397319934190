import React from "react"
import Banner from "../components/header/Banner"
import Cta from "../components/sections/Cta"
import Distributors from "../components/sections/Distributors"
import Experience from "../components/sections/Experience"
import Layout from "../components/layout/Layout"
import Projects from "../components/projects/Projects"
import Services from "../components/services/Services"
import Shop from "../components/shop/Shop"
import SEO from "../components/SEO"

export default () => {
  return (
    <Layout>
      <SEO/>
      <Banner type='main'/>
      <Services/>
      <Cta text="default"/>
      <Experience/>
      <Projects/>
      <Shop/>
      <Distributors/>
    </Layout>
    )
}